import React from 'react';
import axios from 'axios';
import { Button, Form, Row, Col, Select, Card, Rate, Divider} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Spinner from '../../components/spinner';
import AdminHeader from '../../components/adminHeader';
import ExportScoring from './export';

const { Option } = Select;

class scoring extends React.Component{
  state = {loading: false, score: false, horseList: [], exportDisable: true, toExport: [] }

  componentDidMount(){
    this.setState({loading: true})
    axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: "Token "+localStorage.getItem('token')
    }

    axios.get(`/api/horse/horses/list/`).then(
      res => {
          this.setState({horseList: res.data})
          this.setState({loading: false})
      }
    )
  }

  onFinish = values => {
    this.setState({loading: true})
    axios.get(`/api/horse/value/${values.filter_horse}/`).then(
      res => {
        this.setState({score: res.data.results[0]});
        this.setState({loading: false});
        this.setState({exportDisable: false});
        // var newExport = this.state.toExport.concat(this.state.score);
        // this.setState({ toExport: newExport });
        // console.log(this.state.score);
        // console.log(this.state.toExport);
      }
    )
  };

    starVals = (v, aVals) => {
    if (!aVals || aVals.length === 0) {
        return 0;
    }

    aVals.splice(aVals.indexOf(Math.min(...aVals)), 1);
    aVals.splice(aVals.indexOf(Math.min(...aVals)), 1);
    const l = aVals.length;

    if (l === 0) {
        return 0;
    }

    let stars = 0;

    for (let i = 0; i < v.length; i++) {
        const avm = aVals.filter(av => av >= v[i]).length;
        const proportion = avm / l;

        if (proportion === 1) {
            stars = i + 1;
        } else if (proportion >= 0.5) {
            stars = i + 0.5;
        }
    }

    return stars;
  }

    render(){
      const horseList = this.state.horseList.map(function (data, idx) {
        return <Option key={idx} id={idx} value={data.id}>{data.name}</Option>;
      });
      var starValI = [];
      var starValC = [];
      var scoreStarsI = 0;
      var scoreStarsC = 0;

      const percentage = (value) => {
        return (value / 5) * 100;
      }

      if(this.state.score){
        starValI = this.state.score.starValI.map(function (data, idx) {
          return <Col key={idx} xs={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }}>
                    <Card title={"Cada variable >= "+data} className="text_center">
                      {/*<Rate disabled value={idx + 1} />*/}
                      {percentage(idx + 1) + '%'}
                    </Card>
                  </Col>;
        });
        starValC = this.state.score.starValC.map(function (data, idx) {
          return <Col key={idx} xs={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }}>
                    <Card title={"Cada variable >= "+data} className="text_center">
                      {/*<Rate disabled value={idx + 1} />*/}
                      {percentage(idx + 1) + '%'}
                    </Card>
                  </Col>;
        });
        scoreStarsI = this.starVals(this.state.score.starValI, [this.state.score.horseF, this.state.score.fatherF, this.state.score.gf1, this.state.score.gf2, this.state.score.gfmm2]);
        scoreStarsC = this.starVals(this.state.score.starValC, [this.state.score.fgfm, this.state.score.gfpH, this.state.score.damSire, this.state.score.gfpGfm, this.state.score.gfmm, this.state.score.gfgfmm]);
      }
        return(
            <div>
                { localStorage.getItem('token') == null ? this.props.history.push('/login') : ''}
                <AdminHeader title="Scoring" />
                <Spinner spinning={this.state.loading}>
                  <Form name="filter_form" onFinish={this.onFinish}>
                    <Row>
                      <Col xs={{ span: 20 }} md={{ span: 20 }} lg={{ span: 20 }} className="filter_field">
                          <Form.Item name="filter_horse">
                          <Select showSearch placeholder="Ejemplar"optionFilterProp="children"
                              onChange={this.handleChange} allowClear>
                              {horseList}
                          </Select>
                          </Form.Item>
                      </Col>
                      <Col xs={{ span: 4 }} md={{ span: 4 }} lg={{ span: 4 }} className="text_center">
                        <Row>
                          <Col span="3"></Col>
                          <Col span="6" className="text_center">
                            <Button type="primary" htmlType="submit" className="login-form-button" title="Buscar">
                                {this.state.loading ? <Spinner /> : <SearchOutlined />}
                            </Button>  
                          </Col>
                          <Col span="6" className="text_center">
                            <ExportScoring data={[this.state.score]} disable={this.state.exportDisable} />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                  <Row>
                    <Col span="24">
                      {/*<h2 className="text_center">Individual<br /><Rate disabled value={parseInt(this.state.score.starsI)} /></h2>*/}
                      <h2 className="text_center">Individual<br />{ this.state.score && (percentage(scoreStarsI) + '%') }</h2>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
                      <Card title={"Sire ("+((this.state.score.ff) ? this.state.score.ff : '')+")"} bordered={true} className="numberCard male_card">
                        { this.state.score.fatherF }
                      </Card>
                      <Card title={"Sire sire ("+((this.state.score.gf1f) ? this.state.score.gf1f : '')+")"} bordered={true} className="numberCard male_card">
                        { this.state.score.gf1 }
                      </Card>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                      <Card title="Total" bordered={true} className="numberCard">
                        { this.state.score.totalInd }
                      </Card>
                      <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                        <Card title={"Dam dam sire ("+((this.state.score.gfmmf) ? this.state.score.gfmmf : '')+")"} bordered={true} className="numberCard male_card">
                          { this.state.score.gfmm2 }
                        </Card>
                      </Col>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
                      <Card title={"Dam ("+((this.state.score.hf) ? this.state.score.hf : '')+")"} bordered={true} className="numberCard female_card">
                        { this.state.score.horseF }
                      </Card>
                      <Card title={"Dam sire ("+((this.state.score.gf2f) ? this.state.score.gf2f : '')+")"} bordered={true} className="numberCard female_card">
                        { this.state.score.gf2 }
                      </Card>
                    </Col>
                  </Row>
                  <Divider>Tabla de valores</Divider>
                  <Row>
                    <Col xs={{ span: 24 }} md={{ span: 2 }} lg={{ span: 2 }}></Col>
                      {starValI}
                    <Col xs={{ span: 24 }} md={{ span: 2 }} lg={{ span: 2 }}></Col>
                  </Row>
                  <Divider></Divider>
                  <Row>
                    <Col span="24">
                      {/*<h2 className="text_center">Combinado<br /><Rate disabled value={parseInt(this.state.score.starsC)} /></h2>*/}
                      <h2 className="text_center">Combinado<br />{ this.state.score && (percentage(scoreStarsC)) + '%' }</h2>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
                      <Card title={"Sire ("+((this.state.score.gf1f) ? this.state.score.ff : '')+") + Dam ("+((this.state.score.hf) ? this.state.score.hf : '')+")"} bordered={true} className="numberCard male_card">
                        { this.state.score.damSire }
                      </Card>
                      <Card title={"Sire ("+((this.state.score.gf1f) ? this.state.score.ff : '')+") + Dam sire ("+((this.state.score.gf2f) ? this.state.score.gf2f : '')+")"} bordered={true} className="numberCard male_card">
                        { this.state.score.fgfm }
                      </Card>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                      <Card title="Total" bordered={true} className="numberCard">
                        { this.state.score.totalComb }
                      </Card>
                      <Row>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                          <Card title={"Sire ("+((this.state.score.ff) ? this.state.score.ff : '')+") + Dam dam sire ("+((this.state.score.gfmmf) ? this.state.score.gfmmf : '')+")"} bordered={true} className="numberCard male_card">
                            { this.state.score.gfmm }
                          </Card>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                          <Card title={"Sire sire ("+((this.state.score.gf1f) ? this.state.score.gf1f : '')+") + Dam dam sire ("+((this.state.score.gfmmf) ? this.state.score.gfmmf : '')+")"} bordered={true} className="numberCard female_card">
                            { this.state.score.gfgfmm }
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
                      <Card title={"Dam ("+((this.state.score.hf) ? this.state.score.hf : '')+") + Sire sire ("+((this.state.score.gf1f) ? this.state.score.gf1f : '')+")"} bordered={true} className="numberCard female_card">
                        { this.state.score.gfpH }
                      </Card>
                      <Card title={"Sire sire ("+((this.state.score.gf1f) ? this.state.score.gf1f : '')+") + Dam sire ("+((this.state.score.gf2f) ? this.state.score.gf2f : '')+")"} bordered={true} className="numberCard female_card">
                        { this.state.score.gfpGfm }
                      </Card>
                    </Col>
                  </Row>
                  <Divider>Tabla de valores</Divider>
                  <Row>
                    <Col xs={{ span: 24 }} md={{ span: 2 }} lg={{ span: 2 }}></Col>
                      {starValC}
                    <Col xs={{ span: 24 }} md={{ span: 2 }} lg={{ span: 2 }}></Col>
                  </Row>
                </Spinner>
            </div>
        );
    }
}

export default scoring;