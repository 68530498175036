import React from 'react';
import axios from 'axios';
import { Row, Col, Form, Input, Button, Select, DatePicker, Tooltip, Modal, notification} from 'antd';
import { SearchOutlined, PlusSquareOutlined } from '@ant-design/icons';
import Spinner from '../../components/spinner';
import HorsesList from './components/HorsesList';
import FormM from './Form';
import AdminHeader from '../../components/adminHeader';

const { Option } = Select;
const { RangePicker } = DatePicker;

class List extends React.Component{
    state = {
        horses: [], loading: false, regCount:0,
        famOptions: [], counOptions: [], horseOptions: [], horseMOptions: [], regionList: [], paddockOptions: [],
        fname: '', ffamily: '', fcountry: '',
        ffrom: '',//moment(Date(), 'dd').subtract(10, 'year').year(),
        fto: '', //moment(Date(), 'dd').year()
        rowToEdit: [], visible: false, visibleScrap: false
    }

    componentDidMount() {           
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: "Token "+localStorage.getItem('token')
        }

        axios.get('/api/horse/country/list/').then(
            res => {
                this.setState({counOptions: res.data})
            }
        );
        axios.get('/api/horse/family/list/').then(
            res => {
                this.setState({famOptions: res.data})
            }
        );
        axios.get(`/api/horse/horses/list/?sex=h`).then(
            res => {
                this.setState({horseOptions: res.data});
            }
        );
        axios.get(`/api/horse/horses/list/?sex=m`).then(
            res => {
                this.setState({horseMOptions: res.data});
            }
        );
        axios.get(`/api/racecourse/region/list/`).then(
            res => {
                this.setState({regionList: res.data})
            }
        );
        axios.get(`/api/paddock/`).then(
            res => {
                this.setState({paddockOptions: res.data, loading: false})
            }
        );

        this.paginate(1);
    }

    onFinish = values => {
        this.setState({fname: values.filter_name, fcountry: values.filter_country});
            if (values.filter_year && values.filter_year['0'].year()){
                this.setState({ffrom: values.filter_year['0'].year()})
            }
            if (values.filter_year && values.filter_year['1'].year()){
                this.setState({fto: values.filter_year['1'].year()})
            }
        this.paginate(1);
    };

    paginate = pageNum => {
        this.setState({loading: true});
        const url = `/api/horse/list/search/?page=${pageNum}&filter_name=${this.state.fname}&filter_family=${this.state.ffamily}&filter_country=${this.state.fcountry}&filter_from=${this.state.ffrom}&filter_to=${this.state.fto}&admin=true`
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: "Token "+localStorage.getItem('token')
        }
        axios.get(url)
            .then(
                res => {
                    this.setState({
                        horses: res.data.results,
                        loading: false,
                        regCount: res.data.count
                    });
                }
            )
    }

    handleFamilyFilter = (family) => {
        this.setState({ffamily: family});
    }

    showModal = id => {
        this.setState({loading: true});
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: "Token "+localStorage.getItem('token')
        }
        axios.get(`/api/horse/${id}/`).then(
            res => {
                this.setState({rowToEdit: res.data, visible: true, loading: false})
            }
        );
    };

    startScrapping = url =>{
        this.setState({loading: true});
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: "Token "+localStorage.getItem('token')
        }
        axios.get(`/api/horse/scrapping/${url.url}`).then(
            res => {
                console.log(res);
                this.setState({visibleScrap: true, loading: false})
                notification["success"]({
                    message: 'Caballo agregado exitosamente',
                  });
                this.closeScrap();
                this.paginate(1);
            }
        ).catch(error => {
            notification["error"]({
                message: 'Ocurrio un problema, intente de nuevo',
              });
            });
    }

    handleCancel = e => {
        this.setState({
            visible: false,
        });
        if(e === true){
            this.paginate(1);
        }
    };

    showNew = b => {
        this.setState({
            showNew: b,
        });
    };

    regSaved = s =>{
        this.showNew(false)
        if(s === true){
            this.paginate(1);
        }
    }

    closeScrap = e => {
        this.setState({
            visibleScrap: false,
        });
    };

    render(){
        const renderCountries = this.state.counOptions.map(function (data, idx) {
            return <Option key={idx} id={idx} value={data.country}>{data.country}</Option>;
        });
        const renderFamilies = this.state.famOptions.map(function (data, idx) {
            return <Option key={idx} id={idx} value={data.family}>{data.family}</Option>;
        });
        const renderHorses = this.state.horseOptions.map(function (data, idx) {
            return <Option key={idx} id={idx} value={data.id}>{data.name} ({data.country}) {data.year}</Option>;
        });
        const renderHorsesM = this.state.horseMOptions.map(function (data, idx) {
            return <Option key={idx} id={idx} value={data.id}>{data.name} ({data.country}) {data.year}</Option>;
        });
        const regionFilter = this.state.regionList.map(function (data, idx) {
            return <Option key={idx} id={idx} value={data.id}>{data.name}</Option>;
        });

        return(
            <div>
                { localStorage.getItem('token') == null ? this.props.history.push('/login') : ''}
                <Spinner spinning={this.state.loading}>
                    <Row>
                        <Col xs={{ span: 20 }} md={{ span: 22 }} lg={{ span: 22 }}>
                            <AdminHeader title="Caballos" showNew={this.showNew} />
                        </Col>
                        <Col xs={{ span: 4 }} md={{ span: 2 }} lg={{ span: 2 }} className="text_center">
                            <Tooltip title="Scrapping">
                                <Button className="add_buttom" onClick={() => this.setState({visibleScrap: true})}>
                                    <PlusSquareOutlined />
                                </Button>
                            </Tooltip>
                        </Col>
                    </Row>
                    <Form name="filter_form" onFinish={this.onFinish}>
                        <Row>
                            <Col xs={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} className="filter_field">
                                <Form.Item name="filter_name">
                                    <Input placeholder="Nombre" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} className="filter_field">
                                <Form.Item name="filter_family">
                                <Select showSearch placeholder="Familia"optionFilterProp="children"
                                    onChange={this.handleFamilyFilter} allowClear>
                                    {renderFamilies}
                                </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} className="filter_field">
                                <Form.Item name="filter_country">
                                    <Select showSearch placeholder="País" optionFilterProp="children">
                                        {renderCountries}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} className="filter_field">
                                <Form.Item name="filter_year">
                                    <RangePicker picker="year" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }} className="text_center">
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className="login-form-button">
                                        {this.state.loading ? <Spinner /> : <SearchOutlined />}
                                    </Button>  
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <HorsesList data={this.state.horses} regCount={this.state.regCount}
                        onPageSelect={this.paginate} edit={(id) => this.showModal(id)} />

                    <FormM visible={this.state.visible} onCancel={this.handleCancel}
                        data={this.state.rowToEdit} countries={renderCountries} regions={regionFilter}
                        families={renderFamilies} horses={renderHorses} horsesM={renderHorsesM} paddocks={this.state.paddockOptions} />

                    <FormM visible={this.state.showNew}  onCancel={this.regSaved} countries={renderCountries} regions={regionFilter}
                    families={renderFamilies} horses={renderHorses} horsesM={renderHorsesM} paddocks={this.state.paddockOptions} />

                    <Modal title="Scrapping" visible={this.state.visibleScrap} footer={[]} onCancel={this.closeScrap} >
                        <Spinner spinning={this.state.loading}>
                            <Form name="scrapForm" onFinish={this.startScrapping}>
                                <Form.Item name="url"
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Campo requerido!',
                                    },
                                    ]}
                                >
                                    <Input placeholder="Nombre" />
                                </Form.Item>
                                <Form.Item className="text_center">
                                    <Button type="primary" htmlType="submit">
                                        Guardar
                                    </Button>  
                                </Form.Item>
                            </Form>
                        </Spinner>
                    </Modal>
                </Spinner>
            </div>
        );
    }
}

export default List;