import React from 'react';
import { Card, Tooltip, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import '../../../css/card.css';

const { Meta } = Card;

class Horsecard extends React.Component{
  state = {isHover: false}

  hoverCard = c =>{
    this.setState({isHover: !this.state.isHover})
  };

  render(){
    return(
      <div>
        {(this.state.isHover) ? 
        <div className="edit_hover" onMouseOut={this.hoverCard}>
          <Tooltip title="Editar">
            <Button type="primary" onClick={() => this.props.edit(this.props.data.id)}>
              <EditOutlined />
            </Button>
          </Tooltip>
        </div>
        : ''}
        <Card className={(this.props.data.sex === 'M' || this.props.data.sex === 'F' ? 'female_card' : 'male_card')} 
          onMouseOver={this.hoverCard}
          actions={[
            <Tooltip placement="bottom" title="Country">
              <div>{this.props.data.country}</div>
            </Tooltip>,
            <Tooltip placement="bottom" title="Family">
              <div>{this.props.data.family}</div>
            </Tooltip>,
            <Tooltip placement="bottom" title="Year">
              <div>{this.props.data.year}</div>
            </Tooltip>,
          ]}
        >
          <Meta
            title= {<center>{this.props.data.name}</center>}
          />
        </Card>
      </div>
    );
  }
}

export default Horsecard;