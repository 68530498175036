import React from 'react';
import axios from 'axios';
import { Modal, Form, Input, Button, Select, notification } from 'antd';
import Spinner from '../../components/spinner';

const { Option } = Select;

class FormM extends React.Component{
    state = {
        loading: false, success: false, error: false, racecourses: [],
        onCancel: this.props.onCancel
    };

    formRef = React.createRef();

    refreshVals(){
        if(this.formRef.current){
            if(this.props.data){
                this.formRef.current.setFieldsValue({
                    name: this.props.data.name,
                    track: this.props.data.track,
                    grade: this.props.data.grade,
                    distance: this.props.data.distance,
                    racecourse: this.props.data.racecourse.id,
                    region: this.props.data.region.id,
                });
            }else{
                this.formRef.current.setFieldsValue({
                    name: '',
                    track: null,
                    grade: '',
                    distance: '',
                    racecourse: null,
                    region: null,
                });
            }
        }
    }

    saveReg = vals =>{
        this.setState({loading: true});
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: "Token "+localStorage.getItem('token')
        }
        if(this.props.data){
            axios.put(`/api/competition/${this.props.data.id}/`, {
                name: vals.name,
                racecourse_id: vals.racecourse,
                track: vals.track,
                grade: vals.grade,
                distance: vals.distance,
                region_id: vals.region,
            }).then(res => {
                notification["success"]({
                    message: 'Registro editado exitosamente',
                  });
                this.state.onCancel(true)
                this.setState({loading: false});
            })
        }else{
            axios.post('/api/competition/', {
                name: vals.name,
                racecourse_id: vals.racecourse,
                track: vals.track,
                grade: vals.grade,
                distance: vals.distance,
                region_id: vals.region,
            }).then(res => {
                notification["success"]({
                    message: 'Registro creado exitosamente',
                  });
                this.state.onCancel(true)
                this.setState({loading: false});
                this.refreshVals();
            })
        }
    }

    getRacecourse = id =>{
        this.setState({loading: true})
        axios.get(`/api/racecourse/byRegion/list/?region=`+id).then(
            res => {
                this.setState({racecourses: res.data, loading: false});
            }
        )
    }

    render(){
        const rlist = (this.state.racecourses.length > 0) ? this.state.racecourses : this.props.racecourseList;

        const racecourseList = rlist.map(function (data, idx) {
            return <Option key={idx} id={idx} value={data.id}>{data.name}</Option>;
        });

        const trackList = this.props.trackList.map(function (data, idx) {
            return <Option key={idx} id={idx} value={data.name}>{data.name}</Option>;
        });

        const regionList = this.props.regionList.map(function (data, idx) {
            return <Option key={idx} id={idx} value={data.id}>{data.name}</Option>;
        });

        return(
            <Modal title={(this.props.data) ? "Editar" : "Nuevo"} visible={this.props.visible}
                footer={[]} onCancel={this.props.onCancel} >
                <Spinner spinning={this.state.loading}>
                    <Form name="newForm" ref={this.formRef}
                        initialValues={(this.props.data) ? {
                            name: this.props.data.name,
                            track: this.props.data.track,
                            grade: this.props.data.grade,
                            distance: this.props.data.distance,
                            racecourse: this.props.data.racecourse_id,
                            region: this.props.data.region_id,
                        } : ''}
                        onFinish={this.saveReg}
                        >
                        {(this.props.data) ? this.refreshVals() : ''}
                        <Form.Item name="region" rules={[
                            {
                                required: true,
                                message: 'Campo requerido!',
                            },
                            ]}>
                            <Select placeholder="Región" onChange={this.getRacecourse}>
                                {regionList}
                            </Select>
                        </Form.Item>
                        <Form.Item name="name"
                            rules={[
                            {
                                required: true,
                                message: 'Campo requerido!',
                            },
                            ]}
                        >
                            <Input placeholder="Nombre" />
                        </Form.Item>
                        <Form.Item name="racecourse" rules={[
                            {
                                required: true,
                                message: 'Campo requerido!',
                            },
                            ]}>
                            <Select placeholder="Hipódromo" optionFilterProp="children" showSearch >
                                {racecourseList}
                            </Select>
                        </Form.Item>
                        <Form.Item name="track" rules={[
                            {
                                required: true,
                                message: 'Campo requerido!',
                            },
                            ]}>
                            <Select placeholder="Pista">
                                {trackList}
                            </Select>
                        </Form.Item>
                        <Form.Item name="grade"
                            rules={[
                            {
                                required: true,
                                message: 'Campo requerido!',
                            },
                            ]}
                        >
                            <Input placeholder="Grado" />
                        </Form.Item>
                        <Form.Item name="distance"
                            rules={[
                            {
                                required: true,
                                message: 'Campo requerido!',
                            },
                            ]}
                        >
                            <Input placeholder="Distancia" />
                        </Form.Item>
                        <Form.Item className="text_center">
                            <Button type="primary" htmlType="submit">
                                {(this.props.data) ? 'Actualizar' : 'Guardar' }
                            </Button>  
                        </Form.Item>
                    </Form>
                </Spinner>
            </Modal>
        )
    }
}

export default FormM;