import React from 'react';
import { Route } from 'react-router-dom';
import Index from './containers/Index';
import Login from './containers/Login';
import racecourseList from './containers/racecourse/List';
import trackList from './containers/track/List';
import competitionList from './containers/competition/List';
import winnerList from './containers/winner/List';
import horseList from './containers/horse/List';
import scoring from './containers/scoring/index';
import exportScoring from './containers/scoring/export';
import match from './containers/match/index';
import userList from './containers/users/List';
import paddockList from './containers/paddock/List';
import nameList from './containers/names/List';
import articleList from './containers/articles/List';
// import bannerList from './containers/banners/List';

const BaseRouter = () => (
    <div>
        <Route exact path='/' component={Index} />
        <Route exact path='/login' component={Login} />
        <Route exact path='/racecourse' component={racecourseList} />
        <Route exact path='/track' component={trackList} />
        <Route exact path='/competition' component={competitionList} />
        <Route exact path='/winner' component={winnerList} />
        <Route exact path='/horse' component={horseList} />
        <Route exact path='/scoring' component={scoring} />
        <Route exact path='/exportScoring' component={exportScoring} />
        <Route exact path='/match' component={match} />
        <Route exact path='/users' component={userList} />
        <Route exact path='/paddock' component={paddockList} />
        <Route exact path='/names' component={nameList} />
        <Route exact path='/articles' component={articleList} />
        {/* <Route exact path='/banners' component={bannerList} /> */}
    </div>
);

export default BaseRouter;