import React from 'react';
import { List, Row, Col, Pagination } from 'antd';
import HorseCard from './HorseCard';

class HorsesList extends React.Component{

    paginate = (pageNum) => {
        this.props.onPageSelect(pageNum);            
    }

    render(){
        return(
            <div>
                <List grid={{ gutter: 16, xs: 1, md: 3, lg: 4, column: 4 }}
                    dataSource={this.props.data}
                    renderItem={item => (
                    <List.Item>
                        <HorseCard data={item} edit={this.props.edit} />
                    </List.Item>
                    )}
                />
                <Row>
                    <Col xs={{ span: 4 }} md={{ span: 6 }} lg={{ span: 8 }}></Col>
                    <Col xs={{ span: 16 }} md={{ span: 12 }} lg={{ span: 8 }} className="text_center">
                        <Pagination size="small" defaultCurrent={1} total={this.props.regCount}
                        onChange={this.paginate} showSizeChanger={false} defaultPageSize={20} />
                    </Col>
                    <Col xs={{ span: 4 }} md={{ span: 6 }} lg={{ span: 8 }} className="text_right">
                        Registers: {this.props.regCount}</Col>
                </Row>
            </div>
          );
    }
}

export default HorsesList;