import React from 'react';
import axios from 'axios';
import { Modal, Form, Input, Button, Select, notification } from 'antd';
import Spinner from '../../components/spinner';

const { Option } = Select;

class FormM extends React.Component{
    state = {
        loading: false, success: false, error: false,
        onCancel: this.props.onCancel
    };

    formRef = React.createRef();

    refreshVals(){
        if(this.formRef.current){
            this.formRef.current.setFieldsValue({
                name: this.props.data.name,
                track: this.props.data.track,
                region: this.props.data.region.id,
              });
        }
    }

    saveReg = vals =>{
        this.setState({loading: true})
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: "Token "+localStorage.getItem('token')
        }
        if(this.props.data){
            axios.put(`/api/racecourse/${this.props.data.id}/`, {name: vals.name, track: vals.track, region_id: vals.region})
            .then(res => {
                notification["success"]({
                    message: 'Registro editado exitosamente',
                  });
                this.setState({loading: false});
                this.props.onCancel(true);
            })
        }else{
            axios.post('/api/racecourse/', {
                name: vals.name,
                track: vals.track,
                region_id: vals.region
            }).then(res => {
                notification["success"]({
                    message: 'Registro creado exitosamente',
                  });
                this.state.onCancel(true)
            })
        }
    }

    render(){
        const trackList = this.props.trackList.map(function (data, idx) {
            return <Option key={idx} id={idx} value={data.name}>{data.name}</Option>;
        });

        const regionList = this.props.regionList.map(function (data, idx) {
            return <Option key={idx} id={idx} value={data.id}>{data.name}</Option>;
        });

        return(
            <Modal title={(this.props.data) ? "Editar" : "Nuevo"} visible={this.props.visible}
                footer={[]} onCancel={this.props.onCancel} >
                <Spinner spinning={this.state.loading}>
                    <Form name="newForm" ref={this.formRef}
                        initialValues={(this.props.data) ? {
                            name: this.props.data.name,
                            track: this.props.data.track,
                            region: this.props.data.region_id,
                        } : ''}
                        onFinish={this.saveReg}
                        >
                        {(this.props.data) ? this.refreshVals() : ''}
                        <Form.Item name="region" rules={[
                            {
                                required: true,
                                message: 'Campo requerido!',
                            },
                            ]}>
                            <Select placeholder="Región">
                                {regionList}
                            </Select>
                        </Form.Item>
                        <Form.Item name="name"
                            rules={[
                            {
                                required: true,
                                message: 'Campo requerido!',
                            },
                            ]}
                        >
                            <Input placeholder="Nombre" />
                        </Form.Item>
                        <Form.Item name="track" rules={[
                            {
                                required: true,
                                message: 'Campo requerido!',
                            },
                            ]}>
                            <Select placeholder="Pista">
                                {trackList}
                            </Select>
                        </Form.Item>
                        <Form.Item className="text_center">
                            <Button type="primary" htmlType="submit">
                                {(this.props.data) ? 'Actualizar' : 'Guardar' }
                            </Button>  
                        </Form.Item>
                    </Form>
                </Spinner>
            </Modal>
        )
    }
}

export default FormM;