import axios from 'axios'
import * as actionTypes from './actionTypes'

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
}

export const authSuccess = token => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token
    }
}

export const authFail = error => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    }
}

export const logout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('expirationdate');
    localStorage.removeItem('token');
    localStorage.removeItem('userName');
    window.location.reload();
    return {
        type: actionTypes.AUTH_LOGOUT
    }
}

export const checkAuthTimeout = expirationTime => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000)
    }
}

export const authLogin = (email, password) => {
    return dispatch => {
        dispatch(authStart());
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: "Token 03cf971bb813dfe8d93d5682cbc034c527041f77"
        }
        axios.post('/api/rest-auth/login/', {
            email: email,
            password: password
        }).then(res => {
            const token  = res.data.key;
            const expirationdate = new Date(new Date().getTime() + 3600 * 1000);
            localStorage.setItem('token', token);
            localStorage.setItem('expirationDate', expirationdate);

            axios.defaults.headers = {
                "Content-Type": "application/json",
                Authorization: "Token "+token
            }
            axios.get('/api/rest-auth/user/').then(
                res => {
                    localStorage.setItem('userName', res.data.username);
                    dispatch(authSuccess(token));
                    dispatch(checkAuthTimeout(3600));
                }
            ).catch(err => {
                dispatch(authFail(err))
            })
        }).catch(err => {
            dispatch(authFail(err))
        })
    }
}

export const authSignup = (username, email, password1, password2) => {
    return dispatch => {
        dispatch(authStart());
        axios.post('/api/rest-auth/registration/', {
            username:username,
            email: email,
            password1: password1,
            password2: password2
        }).then(res => {
            const token  = res.data.key;
            const expirationdate = new Date(new Date().getTime() + 3600 * 1000);
            localStorage.setItem('token', token);
            localStorage.setItem('expirationdate', expirationdate);
            dispatch(authSuccess(token));
            dispatch(checkAuthTimeout(3600));
        }).catch(err => {
            dispatch(authFail(err))
        })
    }
}

export const autoCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if (token === undefined) {
            dispatch(logout());
        }else{
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            if(expirationDate <= new Date()){
                dispatch(logout());
            }else{
                dispatch(authSuccess(token));
                dispatch(checkAuthTimeout( (expirationDate.getTime() - new Date().getTime()) / 1000));
            }
        }
    }
}