import React from 'react';
import axios from 'axios';
import { Modal, Form, Input, Button, notification } from 'antd';
import Spinner from '../../components/spinner';

class FormM extends React.Component{
    state = {
        loading: false, success: false, error: false
    };

    formRef = React.createRef();

    refreshVals(){
        if(this.formRef.current){
            this.formRef.current.setFieldsValue({
                name: this.props.data.name,
              });
        }
    }

    saveReg = vals =>{
        this.setState({loading: true})
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: "Token "+localStorage.getItem('token')
        }
        if(this.props.data){
            axios.put(`/api/track/${this.props.data.id}/`, {name: vals.name})
            .then(res => {
                notification["success"]({
                    message: 'Registro editado exitosamente',
                  });
                this.setState({loading: false});
                this.props.onCancel(true);
            })
        }else{
            axios.post('/api/track/', {
            name: vals.name
            }).then(res => {
                notification["success"]({
                    message: 'Registro creado exitosamente',
                  });
                this.setState({loading: false});
                this.props.onCancel(true)
            })
        }
    }

    render(){
        return(
            <Modal title={(this.props.data) ? "Editar" : "Nuevo"} visible={this.props.visible}
                footer={[]} onCancel={this.props.onCancel} >
                <Spinner spinning={this.state.loading}>
                    <Form name="newForm" ref={this.formRef}
                        initialValues={(this.props.data) ? {
                            name: this.props.data.name,
                        } : ''}
                        onFinish={this.saveReg}>
                        {(this.props.data) ? this.refreshVals() : ''}
                        <Form.Item name="name"
                            rules={[
                            {
                                required: true,
                                message: 'Campo requerido!',
                            },
                            ]}
                        >
                            <Input placeholder="Nombre" />
                        </Form.Item>
                        <Form.Item className="text_center">
                            <Button type="primary" htmlType="submit">
                                {(this.props.data) ? 'Actualizar' : 'Guardar' }
                            </Button>  
                        </Form.Item>
                    </Form>
                </Spinner>
            </Modal>
        )
    }
}

export default FormM;