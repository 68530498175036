import React from 'react';
import axios from 'axios';
import { Modal, Form, Input, Button, notification, Tabs, DatePicker, Upload } from 'antd';
// import ImgCrop from 'antd-img-crop';
import Spinner from '../../components/spinner';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import moment from 'moment';

const { TabPane } = Tabs;
var today = new Date()

class FormM extends React.Component{
    state = {
        loading: false, success: false, error: false, body_es: '', body_en: '', 
        fileList: (this.props.data) ? [{uid: '-1', name: 'image.png', status: 'done',
        url: this.props.data.cover,}] : []
    };

    formRef = React.createRef();

    refreshVals(){
        if(this.formRef.current){
            this.formRef.current.setFieldsValue({
                title_es: this.props.data.title_es,
                title_en: this.props.data.title_en,
                body_es: this.props.data.body_es,
                body_en: this.props.data.body_en,
                publish_date: (this.props.data) ? moment(this.props.data.publish_date, 'YYYY/MM/DD') : moment(today, 'YYYY-MM-DD')
              });
        }
    }

    saveReg = vals =>{
        this.setState({loading: true})
        axios.defaults.headers = {
            "Content-Type": "application/json",
            // 'content-type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
            Authorization: "Token "+localStorage.getItem('token')
        }
        if(this.props.data){
            axios.put(`/api/articles/${this.props.data.id}/`, 
            {title_es: vals.title_es,
            body_es: this.state.body_es,
            title_en: vals.title_en,
            body_en: this.state.body_en,
            publish_date: vals.publish_date.format('YYYY-MM-DD'),
            // cover: this.state.cover,
            })
            .then(res => {
                notification["success"]({
                    message: 'Registro editado exitosamente',
                  });
                this.setState({loading: false});
                this.props.onCancel(true);
            })
        }else{
            axios.post('/api/articles/', {
            title_es: vals.title_es,
            body_es: this.state.body_es,
            title_en: vals.title_en,
            body_en: this.state.body_en,
            publish_date: vals.publish_date.format('YYYY-MM-DD'),
            // cover: this.state.cover,
            }).then(res => {
                notification["success"]({
                    message: 'Registro creado exitosamente',
                  });
                this.setState({loading: false});
                this.props.onCancel(true)
            })
        }
    }

    // coverOnChange = ({ fileList: newFileList }) => {
    //     this.setState({fileList: newFileList});
    //     if(newFileList[0]){
    //         this.getBase64(newFileList[0].originFileObj, imageUrl =>
    //             this.setState({ cover: imageUrl, loading: false, }),
    //         );
    //     }        
    // };
    
    // coverOnPreview = async file => {
    //     let src = file.url;
    //     if (!src) {
    //       src = await new Promise(resolve => {
    //         const reader = new FileReader();
    //         reader.readAsDataURL(file.originFileObj);
    //         reader.onload = () => resolve(reader.result);
    //       });
    //     }
    //     const image = new Image();
    //     image.src = src;
    //     const imgWindow = window.open(src);
    //     imgWindow.document.write(image.outerHTML);
    // };

    // getBase64(img, callback) {
    //     const reader = new FileReader();
    //     reader.addEventListener('load', () => callback(reader.result));
    //     reader.readAsDataURL(img);
    // }

    render(){
        return(
            <Modal title={(this.props.data) ? "Editar" : "Nuevo"} visible={this.props.visible}
                footer={[]} onCancel={this.props.onCancel} width="70%" >
                <Spinner spinning={this.state.loading}>
                    <Form name="newForm" ref={this.formRef}
                        initialValues={(this.props.data) ? {
                            title_es: this.props.data.title_es,
                            title_en: this.props.data.title_en,
                            body_es: this.props.data.body_es,
                            body_en: this.props.data.body_en,
                            publish_date: (this.props.data) ? moment(this.props.data.publish_date, 'YYYY/MM/DD') : moment(today, 'YYYY-MM-DD')
                        } : ''}
                        onFinish={this.saveReg}>
                        {(this.props.data) ? this.refreshVals() : ''}
                        {/* <Form.Item name="cover">
                            <Upload
                                listType="picture-card"
                                fileList={this.state.fileList}
                                onChange={this.coverOnChange}
                                onPreview={this.coverOnPreview}
                            >
                                {this.state.fileList.length < 1 && '+ Upload'}
                            </Upload>
                        </Form.Item> */}
                        <Form.Item name="publish_date"
                            rules={[
                            {
                                required: true,
                                message: 'Campo requerido!',
                            },
                            ]}
                        >
                            <DatePicker className="full_width" format='YYYY-MM-DD' placeholder="Fecha de publicación" />
                        </Form.Item>
                        <Tabs defaultActiveKey="1" type="card">
                            <TabPane tab="Español" key="1">
                                <Form.Item name="title_es"
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Campo requerido!',
                                    },
                                    ]}
                                >
                                    <Input placeholder="Titulo" />
                                </Form.Item>
                                <Form.Item name="body_es">
                                    <CKEditor
                                        editor={ ClassicEditor }
                                        config={{         
                                            toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', 'insertTable',
                                              'tableColumn', 'tableRow', 'mergeTableCells', 'mediaEmbed', '|', 'undo', 'redo']
                                          }} 
                                        data={(this.props.data) ? this.props.data.body_es : "<p>Cuerpo del articulo!</p>"}
                                        onReady={ editor => {} }
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            this.setState({body_es: data});
                                        } }
                                        onBlur={ ( event, editor ) => {} }
                                        onFocus={ ( event, editor ) => {} }
                                    />
                                </Form.Item>
                            </TabPane>
                            <TabPane tab="English" key="2">
                                <Form.Item name="title_en">
                                    <Input placeholder="Title" />
                                </Form.Item>
                                <Form.Item name="body_en">
                                    <CKEditor
                                        editor={ ClassicEditor }
                                        data={(this.props.data) ? this.props.data.body_en : "<p>Article body!</p>"}
                                        onReady={ editor => {} }
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            this.setState({body_en: data});
                                        } }
                                        onBlur={ ( event, editor ) => {} }
                                        onFocus={ ( event, editor ) => {} }
                                    />
                                </Form.Item>
                            </TabPane>
                        </Tabs>
                        <Form.Item className="text_center">
                            <Button type="primary" htmlType="submit">
                                {(this.props.data) ? 'Actualizar' : 'Guardar' }
                            </Button>  
                        </Form.Item>
                    </Form>
                </Spinner>
            </Modal>
        )
    }
}

export default FormM;