import React from 'react';
import axios from 'axios';
import { Table, Space, Button, Popconfirm, Tooltip, notification, Form, Row, Col, DatePicker, Select } from 'antd';
import { EditOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import New from './New';
import Edit from './Edit';
import Spinner from '../../components/spinner';
import * as filter from '../../global/FilterFun';
import AdminHeader from '../../components/adminHeader';
import Pages from '../../components/pagination';

const { RangePicker } = DatePicker;
const { Option } = Select;

class winnerList extends React.Component{
    state = { winners: [], compList: [], horseList: [], racecourseList: [], trackList: [], viewEdit: false, rowToEdit: [], 
      loading: false, showNew: false, regCount:0, fcomp: 0, fracecourse: 0, fhorse: 0,  from: '', fto: ''}

    componentDidMount(){
      this.setState({loading: true})
      axios.defaults.headers = {
          "Content-Type": "application/json",
          Authorization: "Token "+localStorage.getItem('token')
      }
      axios.get('/api/competition/combo/list/').then(
          res => {
            this.setState({compList: res.data})
          }
      )

      axios.get(`/api/horse/horses/list/`).then(
            res => {
                this.setState({horseList: res.data})
            }
        )

      axios.get('/api/racecourse/combo/list/').then(
        res => {
          this.setState({racecourseList: res.data})
        }
      )

      axios.get('/api/track/').then(
          res => {
            this.setState({trackList: res.data})
          }
      )

      this.paginate(1)
    }

    onFinish = values => {
      this.setState({fcomp: values.filter_competition, fracecourse: values.filter_racecourse, fhorse: values.filter_horse});
          if (values.filter_year && values.filter_year['0']){
              this.setState({ffrom: values.filter_year['0'].format('YYYY-MM-DD')})
          }
          if (values.filter_year && values.filter_year['1']){
              this.setState({fto: values.filter_year['1'].format('YYYY-MM-DD')})
          }
      this.paginate(1);
    };

    paginate = pageNum => {
        this.setState({loading: true});
        const url = `/api/winners/list/search/?page=${pageNum}&filter_comp=${this.state.fcomp}&filter_racecourse=${this.state.fracecourse}&filter_horse=${this.state.fhorse}&filter_from=${this.state.ffrom}&filter_to=${this.state.fto}`
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: "Token "+localStorage.getItem('token')
        }
        axios.get(url)
            .then(
                res => {
                    this.setState({
                        winners: res.data.results,
                        loading: false,
                        regCount: res.data.count
                    });
                }
            )
    }

    getColumnSearchProps = dataIndex => filter.getColumnSearchProps(dataIndex)     
    
    showModal = id => {
      this.setState({loading: true});
      axios.get(`/api/winners/${id}/`).then(
          res => {
            this.setState({rowToEdit: res.data, viewEdit: true, loading: false})
          }
      );
    };

    delete = id => {
      this.setState({loading: true});
      axios.delete(`/api/winners/${id}/`).then(
          res => {
            notification["success"]({
              message: 'Registro eliminado exitosamente',
            });
            this.setState({loading: false});
            this.componentDidMount();
          }
      );
    };
  
    showNew = b => {
      this.setState({
        showNew: b,
      });
    };

    regSaved = s =>{
      this.showNew(false)
      if(s === true){
        this.componentDidMount()
      }
    }
  
    handleCancel = e => {
      this.setState({
        viewEdit: false,
      });
      if(e === true){
        this.componentDidMount()
      }
    };

    render(){
      const compList = this.state.compList.map(function (data, idx) {
        return <Option key={idx} id={idx} value={data.id}>{data.name}</Option>;
      });

      const racecourseList = this.state.racecourseList.map(function (data, idx) {
        return <Option key={idx} id={idx} value={data.id}>{data.name}</Option>;
      });

      const horseList = this.state.horseList.map(function (data, idx) {
        return <Option key={idx} id={idx} value={data.id}>{data.name}</Option>;
      });

        const columns = [
            {
              title: 'Fecha',
              dataIndex: 'comp_date',
            },
            {
              title: 'Ganador',
              render: (record) => record.horse.name,
            },
            {
              title: 'Competición',
              render: (record) => record.competition.name,
            },
            {
              title: 'Hipódromo',
              render: (record) => record.racecourse.name,
            },
            {
                title: 'Acciones',
                key: 'action',
                width: '20%',
                render: (text, record) => (
                  <Space size="middle">
                    <Tooltip title="Editar">
                      <Button type="primary" onClick={() => this.showModal(record.id)}>
                        <EditOutlined />
                      </Button>
                    </Tooltip>
                    <Popconfirm title="Eliminará un registro, ¿continuar?"
                      onConfirm={() => this.delete(record.id)}
                      onCancel={this.cancel}
                      okText="Si"
                      cancelText="No"
                    >
                      <Tooltip title="Eliminar">
                        <Button type="primary" danger>
                          <DeleteOutlined />
                        </Button>
                      </Tooltip>
                    </Popconfirm>
                  </Space>
                ),
            },
          ];
        return(
            <div>
                { localStorage.getItem('token') == null ? this.props.history.push('/login') : ''}
                <AdminHeader title="Ganadores" showNew={this.showNew} />
                <Spinner spinning={this.state.loading}>
                  <Form name="filter_form" onFinish={this.onFinish}>
                    <Row>
                      <Col xs={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} className="filter_field">
                          <Form.Item name="filter_year">
                              <RangePicker />
                          </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} className="filter_field">
                          <Form.Item name="filter_horse">
                          <Select showSearch placeholder="Ganador"optionFilterProp="children"
                              onChange={this.handleChange} allowClear>
                              {horseList}
                          </Select>
                          </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} className="filter_field">
                          <Form.Item name="filter_competition">
                          <Select showSearch placeholder="Competición"optionFilterProp="children"
                              onChange={this.handleChange} allowClear>
                              {compList}
                          </Select>
                          </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} className="filter_field">
                          <Form.Item name="filter_racecourse">
                          <Select showSearch placeholder="Hipódromo"optionFilterProp="children"
                              onChange={this.handleChange} allowClear>
                              {racecourseList}
                          </Select>
                          </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }} className="text_center">
                          <Form.Item>
                              <Button type="primary" htmlType="submit" className="login-form-button">
                                  {this.state.loading ? <Spinner /> : <SearchOutlined />}
                              </Button>  
                          </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                  <Table columns={columns} dataSource={this.state.winners} pagination={false} />
                  <Pages regCount={this.state.regCount} paginate={this.paginate} />

                  <Edit visible={this.state.viewEdit} onCancel={this.handleCancel}
                    data={this.state.rowToEdit} compList={this.state.compList} horseList={this.state.horseList}
                    racecourseList={this.state.racecourseList} trackList={this.state.trackList} />

                  <New visible={this.state.showNew}  onCancel={this.regSaved}
                    compList={this.state.compList} horseList={this.state.horseList} racecourseList={this.state.racecourseList} 
                    trackList={this.state.trackList} />
                </Spinner>
            </div>
        );
    }
}

export default winnerList;