import React from 'react';
import axios from 'axios';
import { Table} from 'antd';
import Spinner from '../../components/spinner';
import * as filter from '../../global/FilterFun';
import AdminHeader from '../../components/adminHeader';

class userList extends React.Component{
    state = { users: [], searchText: '',
    searchedColumn: '', visible: false, rowToEdit: [], loading: true,
    showNew: false}

    componentDidMount(){
      this.setState({loading: true})
      axios.defaults.headers = {
          "Content-Type": "application/json",
          Authorization: "Token "+localStorage.getItem('token')
      }
      axios.get('/api/user/user/list/').then(
          res => {
              this.setState({users: res.data, loading: false})
          }
      )
    }

    getColumnSearchProps = dataIndex => filter.getColumnSearchProps(dataIndex) 

    render(){
        const columns = [
          {
            title: 'Registro',
            dataIndex: 'date_joined',
            ...this.getColumnSearchProps('date_joined'),
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortDirections: ['ascend', 'descend'],
          },
          {
            title: 'Nombre',
            dataIndex: 'first_name',
            ...this.getColumnSearchProps('first_name'),
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortDirections: ['ascend', 'descend'],
          },
          {
            title: 'Apellido',
            dataIndex: 'last_name',
            ...this.getColumnSearchProps('last_name'),
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortDirections: ['ascend', 'descend'],
          },
          {
            title: 'Email',
            dataIndex: 'email',
            ...this.getColumnSearchProps('email'),
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortDirections: ['ascend', 'descend'],
          },
        ];
        return(
            <div>
                { localStorage.getItem('token') == null ? this.props.history.push('/login') : ''}
                <AdminHeader title="Usuarios" showNew={this.showNew} />
                <Spinner spinning={this.state.loading}>
                  <Table columns={columns} dataSource={this.state.users} />
                </Spinner>
            </div>
        );
    }
}

export default userList;