import React from 'react';
import { render } from 'react-dom';
import { Button} from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import {
    ExcelExport,
    ExcelExportColumn,
    ExcelExportColumnGroup,
  } from "@progress/kendo-react-excel-export";

class exportMatch extends React.Component {
    render() {
        const _exporter = React.createRef();

        const exportExcel = () => {
            if (_exporter.current) {
            _exporter.current.save();
            }
        };
        const lsire = "Sire ("+this.props.data[0].ff+")"
        const ldam = "Dam ("+this.props.data[0].hf+")"
        const lsireSire = "Sire sire ("+this.props.data[0].gf1f+")"
        const ldamSire = "Dam sire ("+this.props.data[0].gf2f+")"
        const ldamDamSire = "Dam dam sire ("+this.props.data[0].gfmmf+")"
        const lsireDam = "Sire ("+this.props.data[0].ff+") + Dam ("+this.props.data[0].hf+")"
        const lsireDamSire = "Sire ("+this.props.data[0].ff+") + Dam sire ("+this.props.data[0].gf2f+")"
        const lsireDamDamSire = "Sire ("+this.props.data[0].ff+") + Dam dam sire ("+this.props.data[0].gfmmf+")"
        const ldamSireSire = "Dam ("+this.props.data[0].hf+") + Sire sire ("+this.props.data[0].gf1f+")"
        const lsireSireDamSire = "Sire sire ("+this.props.data[0].gf1f+") + Dam sire ("+this.props.data[0].gf2f+")"
        const lsireSireDamDamSire = "Sire sire ("+this.props.data[0].gf1f+") + Dam dam sire ("+this.props.data[0].gfmmf+")"
        
        return (
            <div>
                <Button type="primary" className="login-form-button" id="exportBtn" title="Exportar"  onClick={exportExcel}
                    disabled={ this.props.disable }>
                    {<DownloadOutlined />}
                </Button>

                <ExcelExport data={this.props.data} fileName="Match.xlsx" ref={_exporter} >
                    <ExcelExportColumn field="matchName" title="Combinación" width={100} />
                    <ExcelExportColumnGroup title="Individual" headerCellOptions={{ textAlign: "center", background: "#41a9bd", }} >
                        <ExcelExportColumn field="fatherF" title={lsire} width={100} headerCellOptions={{background: "#41a9bd", }} />
                        <ExcelExportColumn field="horseF" title={ldam} width={100} headerCellOptions={{background: "#41a9bd", }} />
                        <ExcelExportColumn field="gf1" title={lsireSire} width={100} headerCellOptions={{background: "#41a9bd", }} />
                        <ExcelExportColumn field="gf2" title={ldamSire} width={100} headerCellOptions={{background: "#41a9bd", }} />
                        <ExcelExportColumn field="gfmm2" title={ldamDamSire} width={100} headerCellOptions={{background: "#41a9bd", }} />
                        <ExcelExportColumn field="totalInd" title="Total" width={100} headerCellOptions={{background: "#41a9bd", }} />
                    </ExcelExportColumnGroup>
                    <ExcelExportColumnGroup title="Combinado" headerCellOptions={{ textAlign: "center", background: "#502878", }} >
                        <ExcelExportColumn field="damSire" title={lsireDam} width={100} headerCellOptions={{background: "#502878", }} />
                        <ExcelExportColumn field="fgfm" title={lsireDamSire} width={100} headerCellOptions={{background: "#502878", }} />
                        <ExcelExportColumn field="gfmm" title={lsireDamDamSire} width={100} headerCellOptions={{background: "#502878", }} />
                        <ExcelExportColumn field="gfpH" title={ldamSireSire} width={100} headerCellOptions={{background: "#502878", }} />
                        <ExcelExportColumn field="gfpGfm" title={lsireSireDamSire} width={100} headerCellOptions={{background: "#502878", }} />
                        <ExcelExportColumn field="gfgfmm" title={lsireSireDamDamSire} width={100} headerCellOptions={{background: "#502878", }} />
                        <ExcelExportColumn field="totalComb" title="Total" width={100} headerCellOptions={{background: "#502878", }} />
                    </ExcelExportColumnGroup>
                </ExcelExport>
            </div>
        );
    }
}

export default exportMatch;