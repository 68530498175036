import React from 'react';
import axios from 'axios';
import { Modal, Form, InputNumber, Button, Select, notification, DatePicker, Radio, Input } from 'antd';
import Spinner from '../../components/spinner';

const { Option } = Select;

class New extends React.Component{
    state = {
        loading: false, success: false, error: false, racecourse: null, track: '', grade: 1, distance: '',
        racecourse_name: '', competition_name: '', onCancel: this.props.onCancel
    };

    saveReg = vals =>{
        this.setState({loading: true})
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: "Token "+localStorage.getItem('token')
        }
        axios.post('/api/winners/', {
            comp_date: vals.comp_date.format('YYYY-MM-DD'),
            competition_id: vals.competition,
            comp_name: this.state.competition_name,
            horse_id: vals.horse,
            racecourse_id: this.state.racecourse,
            racecourse_name: this.state.racecourse_name,
            comp_track: this.state.track,
            comp_grade: this.state.grade,
            comp_distance: this.state.distance,
            prize: vals.prize
        }).then(res => {
            notification["success"]({
                message: 'Registro creado exitosamente',
                });
            this.props.onCancel(true);
            this.setState({loading: false});
        }).catch(e => {
            notification["error"]({
                message: e.response.data.msgs,
                });
            this.setState({loading: false});
        })
    }

    getComp = id =>{
        this.setState({loading: true});
        axios.get(`/api/competition/${id}/`).then(
            res => {
                this.setState({racecourse: res.data.racecourse.id, track:res.data.track, grade:res.data.grade,
                    distance:res.data.distance, racecourse_name: res.data.racecourse.name,
                    competition_name: res.data.name, loading: false})
            }
        );
    }

    render(){
        const compList = this.props.compList.map(function (data, idx) {
            return <Option key={idx} id={idx} value={data.id}>{data.name}</Option>;
        });

        const horseList = this.props.horseList.map(function (data, idx) {
            return <Option key={idx} id={idx} value={data.id}>{data.name} ({data.country}) {data.year}</Option>;
        });

        const racecourseList = this.props.racecourseList.map(function (data, idx) {
            return <Option key={idx} id={idx} value={data.id}>{data.name}</Option>;
        });

        const trackList = this.props.trackList.map(function (data, idx) {
            return <Option key={idx} id={idx} value={data.name}>{data.name}</Option>;
        });

        return(
            <Modal title={(this.props.data) ? "Editar" : "Nuevo"} visible={this.props.visible}
                footer={[]} onCancel={this.props.onCancel} >
                <Spinner spinning={this.state.loading}>
                    <Form name="newForm" 
                        onFinish={this.saveReg}
                        >
                        <Form.Item name="comp_date"
                            rules={[
                            {
                                required: true,
                                message: 'Campo requerido!',
                            },
                            ]}
                        >
                            <DatePicker className="full_width" />
                        </Form.Item>
                        <Form.Item name="competition" rules={[
                            {
                                required: true,
                                message: 'Campo requerido!',
                            },
                            ]}>
                            <Select placeholder="Competición" showSearch optionFilterProp="children" onChange={this.getComp}>
                                {compList}
                            </Select>
                        </Form.Item>
                        <Form.Item name="horse" rules={[
                            {
                                required: true,
                                message: 'Campo requerido!',
                            },
                            ]}>
                            <Select placeholder="Ganador" showSearch optionFilterProp="children">
                                {horseList}
                            </Select>
                        </Form.Item>
                        <Select placeholder="Hipódromo" showSearch optionFilterProp="children" className="full_width ant-row ant-form-item"
                            value={this.state.racecourse} 
                            onChange={(r) => this.setState({racecourse: r})} >
                            {racecourseList}
                        </Select>
                        <Select placeholder="Pista" className="full_width ant-row ant-form-item"
                         value={this.state.track} onChange={(t) => this.setState({track: t})}>
                            {trackList}
                        </Select>
                        <Radio.Group name="grade" buttonStyle="solid" className="full_width ant-row ant-form-item" 
                            value={this.state.grade} 
                            onChange={(g) => this.setState({grade: g.target.value})}>
                            <Radio.Button value={0} disabled>Grado</Radio.Button>
                            <Radio.Button value={1}>1</Radio.Button>
                            <Radio.Button value={2}>2</Radio.Button>
                            <Radio.Button value={3}>3</Radio.Button>
                        </Radio.Group>
                        <div className="full_width ant-row ant-form-item">
                            <Input name="distance" placeholder="Distancia" className="full_width" 
                                value={this.state.distance} 
                                onChange={d => this.setState({distance: d.target.value})} />
                        </div>
                        <Form.Item name="prize"
                            rules={[
                            {
                                required: true,
                                message: 'Campo requerido!',
                            },
                            ]}
                        >
                            <InputNumber placeholder="Premio" className="full_width"
                                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                        </Form.Item>
                        <Form.Item className="text_center">
                            <Button type="primary" htmlType="submit">
                                Guardar
                            </Button>  
                        </Form.Item>
                    </Form>
                </Spinner>
            </Modal>
        )
    }
}

export default New;