import React from 'react';
import axios from 'axios';
import { Row, Col, Card } from 'antd';
import Spinner from '../components/spinner';

class Index extends React.Component{
    state = { loadingHorse: false, loadingWin: false, loadingComp: false, horseCount: 0, winnersCount: 0, compCount: 0}

    componentDidMount(){
        this.setState({loadingHorse: true, loadingWin: true, loadingComp: true});
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: "Token "+localStorage.getItem('token')
        }
        axios.get(`/api/horse/`)
            .then(
                res => {
                    this.setState({
                        loadingHorse: false,
                        horseCount: res.data.count
                    });
                }
            )

        axios.get(`/api/winners/`)
            .then(
                res => {
                    this.setState({
                        loadingWin: false,
                        winnersCount: res.data.count
                    });
                }
            )

        axios.get(`/api/competition/`)
            .then(
                res => {
                    this.setState({
                        loadingComp: false,
                        compCount: res.data.length
                    });
                }
            )
    }
    render(){
        return(
            <div>
                { localStorage.getItem('token') == null ? this.props.history.push('/login') : ''}
                <Row>
                    <Col id="cover" span={24}>
                        <div className="site-card-wrapper">
                            <Row gutter={16}>
                            <Col span={8}>
                                <Spinner spinning={this.state.loadingHorse}>
                                    <Card title="Caballos" bordered={true} className="ind_card_big">
                                        {this.state.horseCount}
                                    </Card>
                                </Spinner>
                            </Col>
                            <Col span={8}>
                                <Spinner spinning={this.state.loadingWin}>
                                    <Card title="Carreras" bordered={true} className="ind_card_big">
                                        {this.state.winnersCount}
                                    </Card>
                                </Spinner>
                            </Col>
                            <Col span={8}>
                                <Spinner spinning={this.state.loadingComp}>
                                    <Card title="Competiciones" bordered={true} className="ind_card_big">
                                        {this.state.compCount}  
                                    </Card>
                                </Spinner>
                            </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Index;