import React from 'react';
import { Row, Col, Button, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

class AdminHeader extends React.Component{
    showNew = (v) => {
        this.props.showNew(v)
    }

    render(){
        return(<Row className="with_space">
            <Col xs={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                <h1>{this.props.title}</h1>
            </Col>
            <Col xs={{ span: 4 }} md={{ span: 10 }} lg={{ span: 10 }}></Col>
            <Col xs={{ span: 8 }} md={{ span: 6 }} lg={{ span: 6 }} className="text_right">
            { (this.props.showNew) ?
                <Tooltip title="Nuevo">
                    <Button className="add_buttom" onClick={this.showNew}>
                        <PlusOutlined />
                    </Button>
                </Tooltip>
            : '' }
            </Col>
        </Row>)
    }
}

export default AdminHeader;