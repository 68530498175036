import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu, Row, Col } from 'antd';
import * as actions from '../store/actions/auth';
import { MenuUnfoldOutlined, MenuFoldOutlined, BarsOutlined, AppstoreOutlined, TrophyOutlined, ScheduleOutlined, RiseOutlined, 
  UserOutlined, PartitionOutlined, IdcardOutlined, FlagOutlined } from '@ant-design/icons';
import '../css/layout.css';

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

class AdminLayout extends React.Component {
  state = {
    collapsed: false,
  };

  componentDidMount() {
    actions.autoCheckState();
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

    render(){
      return (
        <Layout>
          <Sider trigger={null} collapsible collapsed={this.state.collapsed}>
            <div className="logo">
              <a href="/"><img src={process.env.PUBLIC_URL + '/logo.png'} alt="logo" /></a>
            </div>
            <Menu theme="dark" mode="inline">
              <Menu.Item key="1" icon={<AppstoreOutlined />}>
                <Link to="/horse">Caballos</Link>
              </Menu.Item>
              <Menu.Item key="2" icon={<TrophyOutlined />}>
                <Link to="/winner">Ganadores</Link>
              </Menu.Item>
              <Menu.Item key="3" icon={<ScheduleOutlined />}>
                <Link to="/competition">Competiciones</Link>
              </Menu.Item>
              <Menu.Item key="4" icon={<BarsOutlined />}>
                <Link to="/racecourse">Hipódromos</Link>
              </Menu.Item>
              <Menu.Item key="5" icon={<BarsOutlined />}>
                <Link to="/track">Pistas</Link>
              </Menu.Item>
              <Menu.Item key="6" icon={<BarsOutlined />}>
                <Link to="/paddock">Potreros</Link>
              </Menu.Item>
              <Menu.Item key="7" icon={<RiseOutlined />}>
                <Link to="/scoring">Scoring</Link>
              </Menu.Item>
              <Menu.Item key="8" icon={<PartitionOutlined />}>
                <Link to="/match">Match</Link>
              </Menu.Item>
              <Menu.Item key="9" icon={<IdcardOutlined />}>
                <Link to="/names">Nombres</Link>
              </Menu.Item>
              <Menu.Item key="10" icon={<IdcardOutlined />}>
                <Link to="/articles">Artículos</Link>
              </Menu.Item>
              {/* <Menu.Item key="12" icon={<FlagOutlined />}>
                <Link to="/banners">Banners</Link>
              </Menu.Item> */}
              <Menu.Item key="11" icon={<UserOutlined />}>
                <Link to="/users">Usuarios</Link>
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout className="site-layout">
            <Header className="headerCont" style={{ padding: 0 }}>
              <Row>
                <Col span={4} style={{color:"#fff"}}>
                {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                  className: 'trigger',
                  onClick: this.toggle,
                })}
                </Col>
                <Col span={16}></Col>
                <Col span={4}>
                  <Menu className="menu" mode="horizontal" defaultSelectedKeys={['1']}>
                    { 
                      this.props.isauthenticated ?
                      // localStorage.getItem('token') != null ?
                      <SubMenu key="2" title={localStorage.getItem('userName')}>
                        <Menu.Item key="3">
                          <Link to="/login" onClick={actions.logout}>Log out</Link>
                        </Menu.Item>
                      </SubMenu>
                      :
                        <Menu.Item key="1">
                          <Link to="/login">Log in</Link>
                        </Menu.Item>
                    }
                  </Menu>
                </Col>
              </Row>
            </Header>
            <Content className="site-layout-background"
              style={{
                margin: '24px 16px',
                padding: 24,
                minHeight: 280,
              }} { ...this.props }
            >
            </Content>
          </Layout>
        </Layout>
      );
    }
}

export default AdminLayout;
