import React from 'react';
import {connect} from 'react-redux';
import { Form, Input, Button, Alert, Row, Col } from 'antd';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import * as actions from '../store/actions/auth';
import Spinner from '../components/spinner';

const LoginForm = (props) => {
  const onFinish = values => {
    props.onAuth(values.email, values.password);
  };

  let errorMessage = null;
    if(props.error){
        errorMessage = (
            <Alert message={props.error.message} type="error" showIcon closable
            className="with_space" />
        )
    }

  return (
      <div>
        { errorMessage}
        { props.token ? window.location.replace('/')
        :
            <Spinner spinning={props.loading}>
                <Row>
                    <Col xs={{ span: 4 }} md={{ span: 6 }} lg={{ span: 8 }}></Col>
                    <Col xs={{ span: 16 }} md={{ span: 12 }} lg={{ span: 8 }}>
                        <Form name="normal_login" className="login-form"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            >
                            <Form.Item name="email"
                                rules={[
                                {
                                    required: true,
                                    message: 'Please input your email!',
                                },
                                ]}
                            >
                                <Input prefix={<MailOutlined className="site-form-item-icon" />} 
                                placeholder="Email" />
                            </Form.Item>
                            <Form.Item name="password"
                                rules={[
                                {
                                    required: true,
                                    message: 'Please input your Password!',
                                },
                                ]}
                            >
                                <Input.Password
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                placeholder="Password"
                                />
                            </Form.Item>
                            <Form.Item className="text_center">
                                <Button type="primary" htmlType="submit" className="login-form-button">
                                Log in
                                </Button>  
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col xs={{ span: 4 }} md={{ span: 6 }} lg={{ span: 8 }}></Col>
                </Row>
            </Spinner>
        }
        </div>
  );
};

const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        error: state.error,
        token: state.token,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (email, password) => dispatch(actions.authLogin(email, password))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);