import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { Modal, Form, Input, Button, Select, notification, DatePicker, Radio, Row, Col } from 'antd';
import Spinner from '../../components/spinner';

const { Option } = Select;

class FormM extends React.Component{
    state = {
        loading: false, success: false, error: false, regionVal: '',
        onCancel: this.props.onCancel, paddockOptions: []
    };

    formRef = React.createRef();

    refreshVals(){
        if(this.formRef.current){
            this.formRef.current.setFieldsValue({
                name: this.props.data.name,
                year: moment(this.props.data.year, 'YYYY'),
                country: this.props.data.country,
                family: this.props.data.family,
                father: this.props.data.father_id,
                mother: this.props.data.mother_id,
                sex: this.props.data.sex,
                paddock: (this.props.data.paddock) ? this.props.data.paddock.id : '',
                stud: this.props.data.stud,
                // region: (this.props.data.paddock) ? this.props.data.paddock.region_id : '',
              });
        }
    }

    saveReg = vals =>{
        this.setState({loading: true})
        if(this.props.data){
            axios.defaults.headers = {
                "Content-Type": "application/json",
                Authorization: "Token "+localStorage.getItem('token')
            }
            axios.put(`/api/horse/${this.props.data.id}/`, {
                name: vals.name,
                year: vals.year.format('YYYY'),
                country: vals.country,
                family: vals.family,
                father_id: vals.father,
                mother_id: vals.mother,
                sex: vals.sex,
                paddock_id: (vals.paddock) ? vals.paddock : 0,
                stud: vals.stud,
            }).then(res => {
                this.setState({loading: false})
                notification["success"]({
                    message: 'Registro editado exitosamente',
                  });
                this.state.onCancel(true)
            })
        }else{
            axios.defaults.headers = {
                "Content-Type": "application/json",
                Authorization: "Token "+localStorage.getItem('token')
            }
            axios.post('/api/horse/', {
                name: vals.name,
                year: vals.year.format('YYYY'),
                country: vals.country,
                family: vals.family,
                father_id: vals.father,
                mother_id: vals.mother,
                sex: vals.sex,
                paddock_id: (vals.paddock) ? vals.paddock : 0,
                stud: vals.stud,
            }).then(res => {
                this.setState({loading: false})
                notification["success"]({
                    message: 'Registro creado exitosamente',
                  });
                this.state.onCancel(true)
            })
        }
    }

    getMother = id =>{
        this.setState({loading: true});
        axios.get(`/api/horse/${id}/`).then(
            res => {
                this.setState({loading: false});
                this.formRef.current.setFieldsValue({
                    family:res.data.family,
                });
            }
        );
    }

    getPaddocks = id =>{
        this.setState({loading: true, regionVal: id});
        axios.get(`/api/paddock/?region=${id}`).then(
            res => {
                this.setState({paddockOptions: res.data, loading: false})
            }
        );
    }

    render(){
        const plist = (this.state.paddockOptions.length > 0) ? this.state.paddockOptions : this.props.paddocks;

        const renderPaddock = plist.map(function (data, idx) {
            return <Option key={idx} id={idx} value={data.id}>{data.name}</Option>;
        });
        return(
            <Modal title={(this.props.data) ? "Editar" : "Nuevo"} visible={this.props.visible}
                footer={[]} onCancel={this.props.onCancel} >
                <Spinner spinning={this.state.loading}>
                    <Form name="newForm" ref={this.formRef} id="horseForm"
                        initialValues={(this.props.data) ? {
                            name: this.props.data.name,
                            year: moment(this.props.data.year, 'YYYY'),
                            country: this.props.data.country,
                            family: this.props.data.family,
                            father: this.props.data.father_id,
                            mother: this.props.data.mother_id,
                            sex: this.props.data.sex,
                            paddock: (this.props.data.paddock) ? this.props.data.paddock.id : '',
                            stud: this.props.data.stud,
                            region: (this.props.data.paddock) ? this.props.data.paddock.region_id : '',
                        } : ''}
                        onFinish={this.saveReg}
                        >
                        {(this.props.data) ? this.refreshVals() : ''}
                        <Row>
                            <Col span="24">
                                <Form.Item name="father"
                                    rules={[
                                    { required: true, message: 'Campo requerido!', },
                                    ]}>
                                    <Select showSearch placeholder="Padre" optionFilterProp="children">
                                        {this.props.horses}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span="24">
                                <Form.Item name="mother"
                                    rules={[
                                    { required: true, message: 'Campo requerido!', },
                                    ]}>
                                    <Select showSearch placeholder="Madre" optionFilterProp="children" onChange={this.getMother}>
                                        {this.props.horsesM}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span="24">
                                <Form.Item name="name"
                                    rules={[
                                    { required: true, message: 'Campo requerido!',
                                    },]}>
                                    <Input placeholder="Nombre" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span="12" style={{paddingRight:"5px"}}>
                                <Form.Item name="sex" rules={[
                                    { required: true, message: 'Campo requerido!',
                                    },]}>
                                        <Radio.Group buttonStyle="solid" >
                                            <Radio.Button value="" disabled>Sexo</Radio.Button>
                                            <Radio.Button value="H">Macho</Radio.Button>
                                            <Radio.Button value="M">Hembra</Radio.Button>
                                        </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span="12" style={{paddingLeft:"5px"}}>
                                <Form.Item name="family"
                                    rules={[
                                    { required: true, message: 'Campo requerido!',
                                    },]}>
                                    <Select showSearch placeholder="Familia" optionFilterProp="children">
                                        {this.props.families}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span="12" style={{paddingRight:"5px"}}>
                                <Form.Item name="year"
                                    rules={[
                                    { required: true, message: 'Campo requerido!',
                                    },]}>
                                    <DatePicker placeholder="Año" picker="year" className="full_width" />
                                </Form.Item>
                            </Col>
                            <Col span="12" style={{paddingLeft:"5px"}}>
                                <Form.Item name="country"
                                    rules={[
                                    { required: true, message: 'Campo requerido!',
                                    },]}>
                                    <Select showSearch placeholder="País" optionFilterProp="children">
                                        {this.props.countries}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span="24">
                                <Form.Item name="stud">
                                    <Radio.Group buttonStyle="solid" >
                                        <Radio.Button value="" disabled>Semental</Radio.Button>
                                        <Radio.Button value={1}>Si</Radio.Button>
                                        <Radio.Button value={0}>No</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span="12" style={{paddingRight:"5px"}}>
                                <Form.Item name="region">
                                    <Select placeholder="Región" onChange={this.getPaddocks}>
                                        {this.props.regions}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span="12" style={{paddingLeft:"5px"}}>
                                <Form.Item name="paddock">
                                    <Select showSearch placeholder="Potrero" optionFilterProp="children">
                                        {renderPaddock}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item className="text_center">
                            <Button type="primary" htmlType="submit">
                                {(this.props.data) ? 'Actualizar' : 'Guardar' }
                            </Button>  
                        </Form.Item>
                    </Form>
                </Spinner>
            </Modal>
        )
    }
}

export default FormM;