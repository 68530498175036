import React from 'react';
import { Row, Col, Pagination } from 'antd';

const Pages = (props) => {
    return(<Row>
        <Col xs={{ span: 4 }} md={{ span: 6 }} lg={{ span: 8 }}></Col>
        <Col xs={{ span: 16 }} md={{ span: 12 }} lg={{ span: 8 }} className="text_center">
            <Pagination size="small" defaultCurrent={1} total={props.regCount}
            onChange={props.paginate} showSizeChanger={false} defaultPageSize={20} />
        </Col>
        <Col xs={{ span: 4 }} md={{ span: 6 }} lg={{ span: 8 }} className="text_right">
            Registers: {props.regCount}</Col>
    </Row>)
}

export default Pages;