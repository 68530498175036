import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { Modal, Form, InputNumber, Button, Select, notification, DatePicker, Radio, Input } from 'antd';
import Spinner from '../../components/spinner';

const { Option } = Select;

class Edit extends React.Component{
    state = {
        loading: false, success: false, error: false, onCancel: this.props.onCancel, racecourse_name: ''
    };
    
    formRef = React.createRef();

    refreshVals(){
        if(this.formRef.current){
            this.formRef.current.setFieldsValue({
                comp_date: moment(this.props.data.comp_date, 'YYYY/MM/DD'),
                competition: this.props.data.competition.id,
                horse: this.props.data.horse.id,
                prize: this.props.data.prize,
                distance: this.props.data.comp_distance,
                racecourse: { value: this.props.data.racecourse.id },
                track:this.props.data.comp_track,
                grade: this.props.data.comp_grade,
                racecourse_name: this.props.data.racecourse_name, 
                competition_name: this.props.data.comp_name,
              });
        }
    }

    saveReg = vals =>{
        this.setState({loading: true})
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: "Token "+localStorage.getItem('token')
        }
        axios.put(`/api/winners/${this.props.data.id}/`, {
            comp_date: vals.comp_date.format('YYYY-MM-DD'),
            competition_id: vals.competition,
            comp_name: vals.competition_name,
            horse_id: vals.horse,
            racecourse_id: vals.racecourse.value,
            racecourse_name: vals.racecourse_name,
            comp_track: vals.track,
            comp_grade: vals.grade,
            comp_distance: vals.distance,})
        .then(res => {
            notification["success"]({
                message: 'Registro editado exitosamente',
                });
            this.setState({loading: false});
            this.props.onCancel(true);
        })
    }

    getComp = id =>{
        this.setState({loading: true});
        axios.get(`/api/competition/${id}/`).then(
            res => {
                this.setState({loading: false});
                this.formRef.current.setFieldsValue({
                    distance:res.data.distance,
                    racecourse: { value: res.data.racecourse.id },
                    track:res.data.track,
                    grade:res.data.grade,
                    competition: id,
                    racecourse_name: res.data.racecourse.name, 
                    competition_name: res.data.name,
                });
            }
        );
    }

    raceChange = r =>{
        if(this.formRef.current){
            this.formRef.current.setFieldsValue({
                racecourse_name: r.label, 
            });
        }
    }

    render(){
        const compList = this.props.compList.map(function (data, idx) {
            return <Option key={idx} id={idx} value={data.id}>{data.name}</Option>;
        });

        const horseList = this.props.horseList.map(function (data, idx) {
            return <Option key={idx} id={idx} value={data.id}>{data.name} ({data.country}) {data.year}</Option>;
        });

        const racecourseList = this.props.racecourseList.map(function (data, idx) {
            return <Option key={idx} id={idx} value={data.id}>{data.name}</Option>;
        });

        const trackList = this.props.trackList.map(function (data, idx) {
            return <Option key={idx} id={idx} value={data.name}>{data.name}</Option>;
        });

        return(
            <Modal title="Editar" visible={this.props.visible}
                footer={[]} onCancel={this.props.onCancel} >
                <Spinner spinning={this.state.loading}>
                    <Form name="newForm" ref={this.formRef}
                        initialValues={{
                            comp_date: moment(this.props.data.comp_date, 'YYYY/MM/DD'),
                            competition: (this.props.data.competition) ? this.props.data.competition.id : 0,
                            horse: (this.props.data.horse) ? this.props.data.horse.id : 0,
                            racecourse: { value: (this.props.data.racecourse) ? this.props.data.racecourse.id : 0 },
                            track: this.props.data.comp_track,
                            grade: this.props.data.comp_grade,
                            distance: this.props.data.comp_distance,
                            prize: this.props.data.prize,
                            racecourse_name: this.props.data.racecourse_name,
                            competition_name: this.props.data.comp_name,
                        }}
                        onFinish={this.saveReg}
                        >
                        <Form.Item name="comp_date"
                            rules={[
                            {
                                required: true,
                                message: 'Campo requerido!',
                            },
                            ]}
                        >
                            <DatePicker className="full_width" />
                        </Form.Item>
                        <Form.Item name="competition" rules={[
                            {
                                required: true,
                                message: 'Campo requerido!',
                            },
                            ]}>
                            <Select placeholder="Competición" showSearch optionFilterProp="children" onChange={this.getComp}>
                                {compList}
                            </Select>
                        </Form.Item>
                        <Form.Item name="horse" rules={[
                            {
                                required: true,
                                message: 'Campo requerido!',
                            },
                            ]}>
                            <Select placeholder="Ganador" showSearch optionFilterProp="children" >
                                {horseList}
                            </Select>
                        </Form.Item>
                        <Form.Item name="racecourse" rules={[
                            {
                                required: true,
                                message: 'Campo requerido!',
                            },
                            ]}>
                                <Select placeholder="Hipódromo" showSearch optionFilterProp="children" labelInValue 
                                onChange={this.raceChange} >
                                {racecourseList}
                        </Select>
                        </Form.Item>
                        <Form.Item name="track" rules={[
                            {
                                required: true,
                                message: 'Campo requerido!',
                            },
                            ]}>
                                <Select placeholder="Pista" >
                                    {trackList}
                                </Select>
                        </Form.Item>
                        <Form.Item name="grade" rules={[
                            {
                                required: true,
                                message: 'Campo requerido!',
                            },
                            ]}>
                                <Radio.Group buttonStyle="solid" >
                                    <Radio.Button value={0} disabled>Grado</Radio.Button>
                                    <Radio.Button value={1}>1</Radio.Button>
                                    <Radio.Button value={2}>2</Radio.Button>
                                    <Radio.Button value={3}>3</Radio.Button>
                                </Radio.Group>
                        </Form.Item>
                        <Form.Item name="distance"
                            rules={[
                            {
                                required: true,
                                message: 'Campo requerido!',
                            },
                            ]}
                        >
                            <Input name="distance" placeholder="Distancia" className="full_width" />
                        </Form.Item>
                        <Form.Item name="prize"
                            rules={[
                            {
                                required: true,
                                message: 'Campo requerido!',
                            },
                            ]}
                        >
                            <InputNumber placeholder="Premio" className="full_width"
                                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                        </Form.Item>
                        <Form.Item className="text_center">
                            <Button type="primary" htmlType="submit">
                                {(this.props.data) ? 'Actualizar' : 'Guardar' }
                            </Button>  
                        </Form.Item>
                        <Form.Item name="racecourse_name" hidden={true}>
                            <Input type="text" />
                        </Form.Item>
                        <Form.Item name="competition_name" hidden={true}>
                            <Input type="text" />
                        </Form.Item>
                    </Form>
                </Spinner>
                {(this.props.data) ? this.refreshVals() : ''}
            </Modal>
        )
    }
}

export default Edit;