import React from 'react';
import axios from 'axios';
import { Table, Space, Button, Popconfirm, Tooltip, notification } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import FormM from './Form';
import Spinner from '../../components/spinner';
import * as filter from '../../global/FilterFun';
import AdminHeader from '../../components/adminHeader';

class racecourseList extends React.Component{
    state = { racecourses: [], trackList: [], visible: false, rowToEdit: [], loading: false, 
    showNew: false, regionList: []}

    componentDidMount(){      
      this.setState({loading: true})
      axios.defaults.headers = {
          "Content-Type": "application/json",
          Authorization: "Token "+localStorage.getItem('token')
      }
      axios.get(`/api/racecourse/`).then(
          res => {
              this.setState({racecourses: res.data, loading:false})
          }
      );

      axios.get('/api/track/').then(
          res => {
            this.setState({trackList: res.data})
          }
      );

      axios.get(`/api/racecourse/region/list/`).then(
          res => {
              this.setState({regionList: res.data, loading:false})
          }
      );
    }

    getColumnSearchProps = dataIndex => filter.getColumnSearchProps(dataIndex)     
    
    showModal = id => {
      this.setState({loading: true});
      axios.get(`/api/racecourse/${id}/`).then(
          res => {
              this.setState({rowToEdit: res.data, visible: true, loading: false})
          }
      );
    };

    delete = id => {
      this.setState({loading: true});
      axios.delete(`/api/racecourse/${id}/`).then(
          res => {
            notification["success"]({
              message: 'Registro eliminado exitosamente',
            });
            this.setState({loading: false});
            this.componentDidMount();
          }
      );
    };
  
    showNew = b => {
      this.setState({
        showNew: b,
      });
    };

    regSaved = s =>{
      this.showNew(false)
      if(s === true){
        this.componentDidMount()
      }
    }
  
    handleCancel = e => {
      this.setState({
        visible: false,
      });
      if(e === true){
        this.componentDidMount()
      }
    };

    render(){
        const trackFilter = this.state.trackList.map(function (data, idx) {
            return {'text' : data.name, 'value' : data.name, 'key' : idx}
        });

        const regionFilter = this.state.regionList.map(function (data, idx) {
          return {'text' : data.name, 'value' : data.name, 'key' : idx}
        });

        const columns = [
            {
              title: 'Región',
              render: (record) => record.region.name,
              filters: regionFilter,
              filterMultiple: false,
              onFilter: (value, record) => record.region.name.indexOf(value) === 0,
              sortDirections: ['descend', 'ascend'],
            },
            {
              title: 'Nombre',
              dataIndex: 'name',
              ...this.getColumnSearchProps('name'),
              sorter: (a, b) => a.name.localeCompare(b.name),
              sortDirections: ['ascend', 'descend'],
            },
            {
              title: 'Pista',
              dataIndex: 'track',
              filters: trackFilter,
              filterMultiple: false,
              onFilter: (value, record) => record.track.indexOf(value) === 0,
              sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Acciones',
                key: 'action',
                width: '20%',
                render: (text, record) => (
                  <Space size="middle">
                    <Tooltip title="Editar">
                      <Button type="primary" onClick={() => this.showModal(record.id)}>
                        <EditOutlined />
                      </Button>
                    </Tooltip>
                    <Popconfirm title="Eliminará un registro, ¿continuar?"
                      onConfirm={() => this.delete(record.id)}
                      onCancel={this.cancel}
                      okText="Si"
                      cancelText="No"
                    >
                      <Tooltip title="Eliminar">
                        <Button type="primary" danger>
                          <DeleteOutlined />
                        </Button>
                      </Tooltip>
                    </Popconfirm>
                  </Space>
                ),
            },
          ];
        return(
            <div>
                { localStorage.getItem('token') == null ? this.props.history.push('/login') : ''}
                <AdminHeader title="Hipódromos" showNew={this.showNew} />
                <Spinner spinning={this.state.loading}>
                  <Table columns={columns} dataSource={this.state.racecourses} pagination={true} />
                  <FormM visible={this.state.visible} onCancel={this.handleCancel}
                    data={this.state.rowToEdit} trackList={this.state.trackList} regionList={this.state.regionList} />

                  <FormM visible={this.state.showNew}  onCancel={this.regSaved}
                    trackList={this.state.trackList} regionList={this.state.regionList} />
                </Spinner>
            </div>
        );
    }
}

export default racecourseList;