import React, { Component } from 'react';
import 'antd/dist/antd.css';
import CustomLayout from './containers/Layout';
import { connect } from 'react-redux';
// import * as actions from './store/actions/auth';

import { BrowserRouter as Router } from 'react-router-dom';
import BaseRouter from './routes';

class App extends Component {
  render() {
    return (
      <div>
          <Router>
            <CustomLayout { ...this.props }>
              <BaseRouter />
            </CustomLayout>
          </Router>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isauthenticated: (state.token != null) ? state.token : 'false',
    token: state.token,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // tryautosignup: () => dispatch(actions.autoCheckState())
    // dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
